<template>
  <div>
    <lj-nav></lj-nav>
    <div class="main">
      <div style="padding: 0px" class="main-title">报告解读与生涯服务咨询——咨询师介绍</div>
      <div>
        <div class="teacher-list">
          <div class="teacher-box" v-for="teacher, index in teacherList" @click="getTeacherDetailInfo(index)">
            <el-image style="width: 100%; height: 100%;overflow: hidden;" :src="teacher.img"></el-image>
            <div class="teacher-name">{{ teacher.name }}</div>
            <!-- <div style="padding: 5px 18%" class="button-wrapper">
              <a class="lj-btn lj-btn-primary lj-btn-block" @click="onLoginClick(index)">在线问题咨询
              </a>
            </div> -->
          </div>
        </div>
      </div>
      <div style="padding: 20px 30%" class="button-wrapper">
        <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="jumpPage('/')">返回
        </a>
      </div>
    </div>

    <!-- 约见 -->
    <div class="pop-wrapper" v-show="loginPopDisplay">
      <div class="login-bg" @click="onLoginPopClose"></div>
      <div class="login-wrapper-bg">
        <div class="main-parts-wrapper">
          <el-collapse class="collapse-panel">
            <el-collapse-item>
              <template slot="title">
                <div class="collapse-title">
                  点击添加问题咨询<i style="padding-left: 0.3rem" class="el-icon-edit"></i>
                </div>
              </template>
              <div class="collapse-detail">
                <div class="login-wrapper">
                  <div class="login">
                    <!-- <div class="login-title">
                      <span class="lj-icon-compass">报告解读与生涯服务咨询</span>
                    </div>
                    <hr /> -->
                    <p style="margin: 0">欢迎使用灯塔智能生涯规划与数据分析系统</p>
                    <p style="margin-bottom: 0">希望我们能给您带来帮助！</p>
                    <hr />
                    <div class="lj-form login-form">
                      <fieldset>
                        <div class="lj-form-group">
                          <input class="lj-radius" type="text" placeholder="请输入您的问题" required=""
                            v-model="questionForm.question" />
                        </div>
                        <div class="lj-form-group">
                          <textarea class="lj-radius" v-model="questionForm.describe" style="min-height: 60px;"
                            placeholder="请输入您的问题详细描述"></textarea>
                        </div>
                        <div class="lj-form-group">
                          <input class="lj-radius" type="text" placeholder="请输入您的电话号码" required=""
                            v-model="questionForm.phone" />
                        </div>
                        <div>
                          <a class="lj-btn lj-btn-primary" @click="onCreateQuestion" style="margin-left: 1rem">
                            提问
                          </a>
                          <span style="width:3em;display: inline-block;"></span>
                          <a class="lj-btn lj-btn-third" @click="onCancelQuestion" style="margin-right: 1rem">
                            清空</a>
                        </div>
                      </fieldset>
                    </div>

                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>

          <div class="part-title">咨询问题列表</div>
          <el-collapse class="collapse-panel" accordion>
            <el-collapse-item v-for="(item, index) in questionList" :key="index">
              <template slot="title">
                <div class="collapse-question">
                  <span>问题：{{ item.issue_title }}</span>
                </div>
              </template>
              <div class="collapse-detail">
                <div class="collapse-question">
                  <div>描述：<p>{{ item.issue_content }}</p>
                  </div>
                </div>
                <div class="msg-item" v-if="
                  item.issue_answer !== null &&
                  item.issue_answer !== undefined &&
                  item.issue_answer !== ''
                ">
                  <div class="msg-avatar">
                    <el-avatar size="small" :src="teacherUrl"></el-avatar>
                    <div>老师</div>
                  </div>
                  <div class="msg-detail">{{ item.issue_answer }}</div>
                </div>
                <div class="msg-item" v-if="
                  item.suggestion_and_comments !== null &&
                  item.suggestion_and_comments !== undefined &&
                  item.suggestion_and_comments !== ''
                ">
                  <div class="msg-avatar">
                    <el-avatar size="small" :src="studentUrl"></el-avatar>
                    <div>学生</div>
                  </div>
                  <div class="msg-detail">{{ item.suggestion_and_comments }}</div>
                </div>
                <p style="font-weight: bold;color: #555;">编辑回复</p>
                <div class="lj-form login-form">
                  <fieldset>
                    <div class="lj-form-group">
                      <textarea :disabled="
                        item.issue_answer === null || item.suggestion_and_comments !== null
                      " class="lj-radius" v-model="sendMsg.msg" style="min-height: 60px;" placeholder="回复"></textarea>
                    </div>
                    <div>
                      <a class="lj-btn lj-btn-primary" @click="onSendMsg(item.issue_id)" style="margin-left: 1rem">
                        反馈
                      </a>
                      <span style="width:3em;display: inline-block;"></span>
                      <a class="lj-btn lj-btn-third" @click="onCancelMsg" style="margin-right: 1rem">
                        清空</a>
                    </div>
                  </fieldset>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="padding: 20px 30%" class="button-wrapper">
          <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="onLoginPopClose">返回
          </a>
        </div>
      </div>
    </div>

    <!-- 老师详情 -->
    <div class="pop-wrapper" v-show="detailPosDisplay">
      <div class="login-bg" @click="onDetailPopClose"></div>
      <div class="login-wrapper-bg">
        <div class="teacher-detail-page">
          <div class="teacher-detail-info">
            <div class="teacher-name">{{ teacherList[curTeacherid].name }}</div>
            <div class="teacher-table">
              <table class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                <!-- <thead>
                  <tr>
                    <th class="lj-text-middle">学科组合</th>
                    <th class="lj-text-middle">可选专业覆盖率</th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr>
                    <td>
                      抖音号
                    </td>
                    <td>
                      增加客户对咨询师了解
                    </td>
                  </tr>
                  <tr>
                    <td>
                      从事生涯个案咨询经验
                    </td>
                    <td>
                      年限
                    </td>
                  </tr>
                  <tr>
                    <td>
                      咨询个案数量
                    </td>
                    <td>
                      个
                    </td>
                  </tr>
                  <tr>
                    <td>
                      是否可做选科咨询
                    </td>
                    <td>
                      是/否
                    </td>
                  </tr>
                  <tr>
                    <td>
                      是否可做高考填报
                    </td>
                    <td>
                      是/否
                    </td>
                  </tr>
                  <tr>
                    <td>
                      是否可做新高考相关讲座
                    </td>
                    <td>
                      是/否
                    </td>
                  </tr>
                  <tr>
                    <td>
                      用户满意度
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      擅长领域
                    </td>
                    <td>
                      多选（选科、高考填报、职业发展、其他）
                    </td>
                  </tr>
                  <tr>
                    <td>
                      咨询风格
                    </td>
                    <td>
                      多选（亲切、热情、高效、其他）
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="padding: 5px 18%;" class="button-wrapper">
              <a class="lj-btn lj-btn-primary lj-btn-block" @click="onLoginClick(curTeacherid)">我要约见
              </a>
            </div>
          </div>
          <div class="teacher-detail-img">
            <el-image style="width: 100%; height: 100%;overflow: hidden;" :src="teacherList[curTeacherid].img"></el-image>
          </div>
        </div>
        <div style="padding: 20px 30%" class="button-wrapper">
          <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="onDetailPopClose">返回
          </a>
        </div>
      </div>
    </div>

    <lj-footer></lj-footer>
  </div>
</template>

<script>
import {
  getIssueList,
  postNewIssue,
  feedBackIssue,
  deleteIssue
} from './../../../api/consult.js'
import LjFooter from '../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
export default {
  name: 'Consultor',
  components: { LjFooter, LjNav },
  data: function () {
    return {
      loginPopDisplay: !1,
      detailPosDisplay: !1,
      curTeacherid: 0,
      teacherList: [
        {
          name: '张昊老师',
          img: require('./../../../icon/zhanghao.png')
        },
        {
          name: '晓雯老师',
          img: require('./../../../icon/xiaowen.png')
        },
        {
          name: '小雨老师',
          img: require('./../../../icon/xiaoyu.png')
        },
        // {
        //   name: '辛星',
        //   info: '新起点教育资深升学规划师',
        //   label: ['热情主动', '细致耐心', '真诚服务', '善于沟通', '经典案例', '有责任心'],
        //   deatil: '',
        //   img: 'https://xqdgk.com/static/img/c4.f1fc3ec.png'
        // },
        // {
        //   name: '陈轲阳',
        //   info: '新起点教育资深升学规划师',
        //   label: ['亲和', '热情', '真诚', '耐心'],
        //   deatil: '',
        //   img: 'https://xqdgk.com/static/img/c5.a7e5845.png',
        // },
        // {
        //   name: '杨秀明',
        //   info: '新起点教育资深升学规划师',
        //   label: ['善于沟通', '心理咨询', '学科指导', '积极主动',],
        //   deatil: '',
        //   img: 'https://xqdgk.com/static/img/c6.c4e82ca.png'
        // },
        // {
        //   name: '刘景南',
        //   info: '新起点教育资深升学规划师',
        //   label: ['名校精英', '留美博士', '经验丰富', '成就学生'],
        //   img: 'https://xqdgk.com/static/img/c7.1563834.png'
        // },
        // {
        //   name: '张薇',
        //   info: '新起点教育资深升学规划师',
        //   label: ['有阅历', '有经验', '有情怀', '有责任'],
        //   deatil: '',
        //   img: 'https://xqdgk.com/static/img/c8.90ed57e.png'
        // },
        // {
        //   name: '胡承金',
        //   info: '新起点教育资深升学规划师',
        //   label: ['热心细致', '知识面广', '规划深入', '责任感强'],
        //   deatil: '',
        //   img: '	https://xqdgk.com/static/img/c9.76d7fa5.png'
        // }
      ],

      teacherUrl: require('./../../../icon/teacher.png'),
      studentUrl: require('./../../../icon/student.png'),
      questionForm: {
        question: '',
        describe: '',
        phone: '',
      },
      ruleForm: {
        //username和password默认为空
        username: '',
        password: '',
        newpassword: '',
        newpassword2: ''
      },
      sendMsg: {
        msg: ''
      },
      questionList: [
        {
          issue_content: '',
          issue_title: '',
          suggestion_and_comments: '',
          issue_answer: '',
          issue_id: ''
        }
      ]
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    getTeacherDetailInfo: function (index) {
      this.curTeacherid = index
      this.detailPosDisplay = 1
    },
    backToMenu: function () {
      this.detailPosDisplay = 0
    },
    onLoginClick: function (index) {
      this.loginPopDisplay = !0
      this.detailPosDisplay = 0
      this.curTeacherid = index
      console.log(this.teacherList[this.curTeacherid])
      this.queryIssueList()
    },
    onLoginPopClose: function () {
      this.loginPopDisplay = !1
    },

    onDetailPopClose: function () {
      this.detailPosDisplay = !1
    },

    queryIssueList: function () {
      getIssueList({
        issue_teacher: this.teacherList[this.curTeacherid],
      }).then(res => {
        if (res.rescode == 200) {
          console.log('getIssueList', res)
          this.questionList = res.issues
        }
      })
    },
    onSendMsg: function (id) {
      if (
        this.sendMsg.msg !== undefined &&
        this.sendMsg.msg !== '' &&
        this.sendMsg.msg !== null
      ) {
        feedBackIssue({
          issue_teacher: this.teacherList[this.curTeacherid],
          issue_id: id,
          suggestion_and_comments: this.sendMsg.msg,
          significance: 5,
        }).then(res => {
          this.$message.success('反馈成功')
          this.queryIssueList()
          this.onCancelMsg()
        })
      }
    },
    onCancelMsg: function () {
      this.sendMsg.msg = ''
    },
    onCreateQuestion: function () {
      if (
        this.questionForm.question !== undefined &&
        this.questionForm.question !== '' &&
        this.questionForm.question != null
      ) {
        postNewIssue({
          issue_teacher: this.teacherList[this.curTeacherid],
          issue_title: this.questionForm.question,
          issue_content: this.questionForm.describe,
          issue_phone: this.questionForm.phone
        }).then(res => {
          if (res.rescode == 200) {
            this.$message.success('询问成功，等待反馈')
            this.queryIssueList()
            this.onCancelQuestion()
          }
        })
      }
    },
    onCancelQuestion: function () {
      this.questionForm.describe = ''
      this.questionForm.question = ''
    },
    onDeleteQuestion: function (id) {
      deleteIssue({ issue_id: id }).then(res => {
        this.$message.success('删除成功')
        this.queryIssueList()
      })
    }
  },
  mounted: function () {

  },
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/test/index.min.css');
@import url('./../../../Public/resources/css/templates/home/index/index.min.css');
@import url('./../../../Public/resources/css/loading.css');
@import url('./../../../Public/utils/element-ui/index.css');

* {
  box-sizing: border-box;
}

.main {
  min-height: 637px;
  background: #fff;
}

.teacher-name {
  font-weight: bold;
  font-size: 1.4em;
  padding: .2em;
  text-align: center;
}

.teacher-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.teacher-box {
  width: 300px;
  // height: 340px;
  // border: 1px solid #e2826d;
  margin: 30px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;

  .teacher-avator {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #e2826d;

    .img {
      width: 100%;
      height: 100%;
    }
  }



  .teacher-info {
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .teacher-label-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .teacher-label {
      border: 1px solid #979fa8;
      border-radius: 5px;
      margin: 3px 8px;
      color: #575a5d;
      font-weight: bolder;
      padding: 1px 8px;
    }
  }
}

.part-title {
  user-select: none;
  font-size: 2rem;
  // border-bottom: 1px solid #f17c67;
  text-align: center;
  padding: 1rem 1rem 1rem;
  font-weight: 700;
  text-align: left;

}


@media only screen and (min-width: 480px) {
  .main {
    min-height: 637px;
    // background: #cfd1cc;
  }
}

.collapse-panel {
  margin: 1rem 0;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .main-parts-wrapper {
    padding: 0.2rem 0.2rem;
  }
}

@media only screen and (min-width: 480px) {
  .main-parts-wrapper {
    padding: 1rem 4rem;
  }
}

.collapse-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.collapse-question {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  padding: 0 2rem;
  width: 100%;

  span {
    padding-right: 4rem;
  }
}

.collapse-detail {
  padding: 1rem 2rem;
}

.msg-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .msg-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .msg-detail {
    margin-left: 1.8rem;
    text-indent: 1.4rem;
    font-size: 1.3rem;
  }
}

.part-title {
  user-select: none;
  font-size: 2rem;
  border-bottom: 1px solid #f17c67;
  padding: 1rem 1rem 1rem;
  font-weight: 700;
  text-align: left;
}

.consultor {
  cursor: pointer;
}

.login-wrapper-bg {
  background-color: #fff;
}

@media only screen and (min-width: 481px) {
  .pop-wrapper {
    width: 100%;
    height: 100vh;
    padding: 8%;
    margin: 0;
    z-index: 1000;
  }
}

.teacher-detail-page {
  display: flex;
  justify-content: space-between;
  padding: 5% 3% 0 3%;

  .teacher-detail-img {
    width: 40%;
  }

  .teacher-detail-info {
    width: 60%;

    .teacher-table {
      margin-right: 2em;
      padding-top: 1em;
    }
  }
}
</style>